// color variants
@import "themes-vars.module.scss";

// third-party
@import "~react-perfect-scrollbar/dist/css/styles.css";

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

#firstName-error::first-letter {
  text-transform: capitalize;
}

#lastName-error::first-letter {
  text-transform: capitalize;
}
#authProviderId-error::first-letter {
  text-transform: capitalize;
}
#address-error::first-letter {
  text-transform: capitalize;
}
#address-error::first-letter {
  text-transform: capitalize;
}
#bio-error::first-letter {
  text-transform: capitalize;
}

td {
  word-wrap: break-word;
}
td.appUserTableAddress {
  overflow-wrap: break-word;
  word-break: break-word;
  width: 300px;
}

/**************
===============
Custom CSS
===============
***************/
.react-datepicker {
  &-wrapper {
    width: 100%;
  }
  &__input-container {
    display: flex !important;
    flex-direction: row-reverse;

    &:focus-visible {
      outline: none !important;
    }

    input {
      padding: 6px 10px 5px 10px !important;
      width: 100%;
      height: 52px;
      // border-color: $grey500;

      &:focus-visible {
        outline: none !important;
      }
    }
  }
  &__calendar-icon {
    top: 5px;
    width: unset !important;
    height: unset !important;
  }
}

// Patients table top and bottom spacing
.patients-table-box {
  > .MuiPaper-root {
    box-shadow: none;
    border: 1px solid #9bd5db;
    padding: 4px;
  }
  .css-o06qih,
  .css-11mc9ap,
  .css-1tbggly,
  .css-10gei56 {
    display: none;
  }
}

.rs-picker {
  &-daterange {
    .rs-input-group {
      border-radius: 36px;
      border-color: $orangeMain;
      &:not(.rs-input-group-disabled):hover {
        border-color: $orangeMain;
      }
      input {
        border-radius: 36px;
      }
    }
  }
}

.graph-control {
  background-color: rgba(245, 245, 245, 0.6);
  border-radius: 12px;
  padding: 51px 6px 12px 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 50vh;
}

.blue-border {
  border: 1px solid #9bd5db;
}

.graph-date {
  position: relative;
  font-size: 9px;
  font-weight: 500;
  line-height: 12px;
  color: #000;
  text-align: center;
  background-color: #fff;

  &::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 90%;
    background-color: #0c74c7;
  }
}

// Accordion table

// One space below means that is my code

.accordion-table {
  .outer {
    position: relative;

    .inner {
      overflow-x: scroll;
      overflow-y: visible;
      margin-left: 340px;
      width: calc(100% - 340px);

      table {
        table-layout: fixed;
        width: 100%;

        th {
          vertical-align: top;
          position: absolute;
          left: 0;
          width: 500px;
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          color: #000;
          width: 100%;
          padding: 10px 0 0;
          border-bottom: 1px solid #bdbdbd;
        }

        td {
          vertical-align: top;
          border-right: 1px solid #bdbdbd;
          font-size: 11px;
          font-weight: 400;
          color: #053c5e;
          width: 100%;
          padding: 10px 0 0 20px;
        }
      }
    }
  }
}

/********
  ==========================
  Summary, Hypoglycemia
  and Glycemic Variability
  responsive
  =========================
  */

.container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "sum sum sum sum"
    "hypo hypo hypo hypo"
    "glyc glyc glyc glyc";

  @media screen and (min-width: 900px) {
    grid-template-areas:
      "sum sum hypo hypo"
      "glyc glyc hypo hypo"
      "glyc glyc . ."
      "glyc glyc . .";
  }

  .summary {
    grid-area: sum;
  }

  .hypoglycemia {
    grid-area: hypo;
  }

  .glycemic {
    grid-area: glyc;
  }
}

// Glucose management

.complex-graph {
  position: relative;
  top: -40px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.glucose-sec {
  margin-top: 4px;
  .active {
    background-color: $primaryMain;
    padding: 4px;
    border-radius: 4px;
  }
}
